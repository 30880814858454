<template>
<div class="section-wrapper">
  <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
    <div class="wrapper-box">
      <div class="left">
        <div class="title">
          <p>上海轨道交通检测认证（集团）有限公司简介</p>
        </div>
          <p v-for="(ele,index) in content" :key="index" class="content">
              {{ele}}
          </p>
      </div>
      <div class="right">
        <div class="img-wrapper">
          <div class="img-inner-wrapper">
            <img :src="imgUrl" alt="">
          </div>
           
        </div>
         
      </div>
    </div>
  </div>
</div>
 
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue"
export default {
  name: "introduce",
  components: {
    breadcrumbs
  },
  props: {
  },
  data() {
    return {
        imgUrl: process.env.BASE_URL + "img/cover.jpg",
        content:[
          "为打造国际一流的轨道交通检测认证机构，上海轨道交通检测认证（集团）有限公司由同济大学于2014年发起成立（简称：SRCC）。公司是上海市高新技术企业，上海市“科技小巨人”培育企业，上海市“专精特新”中小企业，上海市生产性服务业先进示范企业。",
          "公司成立以来，获得了国家认监委（CNCA）批准的涵盖产品、体系和服务全门类的认证机构资质，国家级检验检测机构资质（CMA），中国城市轨道交通装备认证资质（CURC）；获得国家认可委（CNAS）认可的认证机构资质（ISO17065）、检验机构资质（ISO17020）和实验室资质（ISO17025）。",
          "公司已具备了仿真计算、材料检测、型式试验、运营评估、安全评估、服役评估和产品认证等技术服务能力，在轨道交通领域从事检测、试验、评估、认证业务已覆盖全国40多个城市、100多条轨道交通线路。",
          "公司是“十二五”国家科技支撑项目（城轨交通互操作综合测试与认证平台关键技术）牵头单位，是“十三五”国家重点研发计划项目（160公里货运列车、250 公里货运动车、超大城市轨道交通智能维护、港珠澳大桥智能化运维、智能新能源汽车芯片、氢燃料电池电堆等）课题承担单位或任务参与单位。“轨道交通列车车辆服役安全评估体系”被国家认监委列为2017年质量提升计划项目和被交通部列为2018年交通运输行业重点科技项目。",
          "公司与德国、奥地利、英国、挪威、匈牙利等5国9家机构达成互认协议，为“一带一路”轨道交通走出去提供支撑。",
          "在上海市科协的支持下，公司组建了市级社会服务机构“上海智创交通安全工程技术研究院”，进一步推进“产学研用”全方位创新。",
        ]
        
    };
  },
  methods: {
    linkTo: function (value) {
      location.href = value;
    },
  },
  computed:{
      breadcrumbsContents:function(){
          return [{text:'首页',url:'/'},{text:'公司简介',url:'/introduce'},];
      },
  },
  mounted() {},
};
</script>
<style scoped>

.section-wrapper{
  width:100%;
}

.sub-section-wrapper{
  width: 1280px;
  margin:0 auto;
  overflow: hidden;
  position: relative;
  padding-bottom: 80px;
}

.wrapper-box{
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}

.left{
    width:50%;
}
.right{
    width:45%;
}

.right img{
  width:100%;
  display: block;
}

.title{
  color:#333;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.content{
  font-size: 16px;
  line-height: 1.7;
  color:#222;
  text-indent: 32px;
  padding: 5px 0;
}

.img-wrapper{
  border:3px double #ddd;
  padding: 3px;
  margin-top:60px;
}

.img-inner-wrapper{
  overflow: hidden;
}

@keyframes it
{
0% {transform:scale(1.0);}
100% {transform:scale(1.1);}
}

.img-wrapper img{
    animation: it 50s ease infinite alternate 1s;
}

</style>